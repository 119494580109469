import React from "react";
import { authRoles } from "modules/utils/RouteAuth";
import { Redirect } from "react-router-dom";

export const EventsConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  isPrivate: true,
  type: "event",
  auth: authRoles.admin,
  routes: [
    {
      path: "/events-list",
      component: React.lazy(() => import("./index"))
    },
    {
      path: "/create-event",
      component: React.lazy(() => import("./CreateEvent/index"))
    },
    {
      path: "/events",
      component: () => <Redirect to='/events-list' />
    },
    {
      path: "/edit-event/:eventId",
      component: React.lazy(() => import("./CreateEvent/index"))
    },
    {
      path: "/create-event/:eventId",
      component: React.lazy(() => import("./CreateEvent/index"))
    }
  ]
};
