import React from "react";
import { LoginConfig } from "../pages/Login/LoginConfigs";
import { Redirect } from "react-router-dom";
import { ResetPasswordConfig } from "../pages/ResetPassword/PasswordConfig";
import { EventsConfig } from "../pages/Events/EventsConfig";
import { ContentsConfig } from "../pages/Content/ContentConfig";
import { TestingConfig } from "../pages/Testing/TestingConfig";
import { ToccaUtils } from "modules/utils";
import { DashboardConfig } from "../pages/Dashboard/DashboardConfigs";
import { PeopleListConfigs } from "../pages/PeopleList/PeopleListConfigs";
import { ChangePasswordConfig } from "../pages/ChangePassword/ChangePasswordConfigs";
import { UnAuthrizedAccessConfigs } from "../pages/UnAuthrizedAccess/UnAuthrizedAccessConfigs";
import { TeamConfig } from "../pages/Team/TeamConfigs";
import { AboutConfig } from "../pages/About/AboutConfig";
import { AccountConfig } from "../pages/Account/AccountConfig";
import { PollsConfigs } from "../pages/Polls/PollsConfigs";
import { LandingPageConfigs } from "../pages/LandingPage/LandingPageConfig";
import { ConversationsConfig } from "../pages/Conversations/ConversationsConfig";
const routeConfigs = [
  AboutConfig,
  AccountConfig,
  EventsConfig,
  ContentsConfig,
  TestingConfig,
  DashboardConfig,
  TeamConfig,
  LoginConfig,
  ChangePasswordConfig,
  UnAuthrizedAccessConfigs,
  ResetPasswordConfig,
  PeopleListConfigs,
  PollsConfigs,
  LandingPageConfigs,
  ConversationsConfig
];

const routes = [
  ...ToccaUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: "/",
    exact: true,
    isPrivate: true,
    component: () => <Redirect to='/dashboard' />
  }
];

export default routes;
