import React from "react";
import { authRoles } from "modules/utils/RouteAuth";
import { Redirect } from "react-router-dom";

export const ContentsConfig = {
  settings: {
    layout: {
      config: {
        header: { display: true },
        footer: { display: true },
        navbar: { display: true }
      }
    }
  },
  isPrivate: true,
  auth: authRoles.admin,
  routes: [
    {
      path: "/contents",
      component: React.lazy(() => import("./index"))
    },
    {
      path: "/content",
      component: () => <Redirect to='/contents' />
    }
  ]
};
