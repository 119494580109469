import {
  Image,
  Video,
  Recording,
  EventComponent,
  Groups
} from "modules/utils/Firebase/Api";
import { omit } from "lodash";
import firebase, { fieldValue } from "modules/utils/Firebase";
// import { eventChannel } from "redux-saga";

export const addFileToDb = async (payload: any) => {
  try {
    // const ref = Video.reference(payload.organizationId).doc();
    const videoRef = Video.reference(payload.organizationId);
    const imageRef = Image.reference(payload.organizationId);
    const obj = omit(payload, ["organizationId"]);
    // obj.id = ref.id;
    obj.updatedAt = fieldValue.serverTimestamp();
    obj.createdAt = fieldValue.serverTimestamp();
    if (payload.type === "Video") await videoRef.doc(payload.id).set(obj);
    else await imageRef.doc(payload.id).set(obj);
    return true;
  } catch (e) {
    console.error(String(e));
    return false;
  }
};

export const fetchContentFile = async (payload: any) => {
  try {
    const fileList: any[] = [];
    const videoRef = Video.reference(payload);
    const imageRef = Image.reference(payload);
    let data = await videoRef.get();
    data.forEach((doc) => {
      fileList.push(doc.data());
    });
    data = await imageRef.get();
    data.forEach((doc) => {
      fileList.push(doc.data());
    });
    return fileList;
  } catch (e) {
    console.error(String(e));
    return false;
  }
};

export const deleteContentApi = async (payload: any) => {
  try {
    const videoRef = Video.reference(payload.organizationId);
    const imageRef = Image.reference(payload.organizationId);
    const RecordingRef = Recording.reference();
    if (payload.type === "Video") await videoRef.doc(payload.id).delete();
    else if (payload.type === "Image") await imageRef.doc(payload.id).delete();
    else if (payload.type === "Recording")
      await RecordingRef.doc(payload.id).delete();
    return true;
  } catch (e) {
    console.error(String(e));
    return false;
  }
};

export const fetchRecording = async (organizationId: string) => {
  try {
    const getRecordingData = firebase
      .functions()
      .httpsCallable("adminRecording");
    const data = {
      organizationId: organizationId
    }
    const response = await getRecordingData(data)
    const promises = response.data.recording.map(async (data: any) => {
      const EventComponentRef = EventComponent.reference(
        data.organizationId,
        data.eventId
      ).doc(data.roomId);

      if (data?.collection === "groups") {
        const GroupRef = Groups.reference(
          data.organizationId,
          data.eventId
        ).doc(data.roomId);
        const GroupComponentData = await GroupRef.get();
        const groupData = GroupComponentData.data();
        return { ...groupData, ...data };
      } else {
        const EventComponentData = await EventComponentRef.get();
        const eventData = EventComponentData.data()
        return { ...eventData, ...data };
      }
    });
    const roomData = await Promise.all(promises);
    return roomData;
  } catch (e) {
    console.error(String(e));
    return e;
  }
};
