import React from "react";
import { authRoles } from "../../modules/utils/RouteAuth";

export const LandingPageConfigs = {
    settings: {
        layout: {
            config: {
                header: { display: true },
                footer: { display: true },
                navbar: { display: true }
            }
        }
    },
    isPrivate: true,
    auth: authRoles.admin,
    routes: [
        {
            path: "/organization-page",
            component: React.lazy(() => import("./index"))
        }
    ]
};