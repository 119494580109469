import React from "react";
import { authRoles } from "modules/utils/RouteAuth";

export const ConversationsConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  isPrivate: true,
  auth: authRoles.admin,
  type: "conversation",
  routes: [
    {
      path: "/conversations-list",
      component: React.lazy(() => import("./index"))
    },
    {
      path: "/edit-conversation/:conversationId",
      component: React.lazy(() => import("./CreateConversations/index"))
    },
    {
      path: "/create-conversation/:conversationId",
      component: React.lazy(() => import("./CreateConversations/index"))
    }
  ]
};
